.yearwrapper2{
    display: flex;
    flex-direction: column;
    
}
.yeartitle{
    margin-top: 10px;
    font-size: 40px;
    letter-spacing: 2px;
    color: rgb(33, 18, 36);
    font-weight: bold;
}
.pagination-controls{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.pagination-controls span{
font-size: 20px;
padding: 0 10px;
color: #2c1749;
}
.pagination-controls button{
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #5A2F95;
    background-color: #5A2F95;
    color: white;
    border-radius: 5px;
}