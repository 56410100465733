.mainwrapper {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh; /* Высота контейнера на весь экран */
    display: flex;
    flex-direction: column;
}

.mainwrapper img {
    width: 100%;
    max-height: 600px;  
    object-fit: contain;
}
.widestrip{
    background-color: rgba(33, 15, 46, 0.877);
    height: 60px;
    width: 100%;
    font-size: 22px;
    color: rgba(33, 15, 46, 0);
}
.introtext{
    text-align:center ;  /* Выравнивание текста по центру */
    margin: 0;      /* Выравнивание самого блока по центру */
    max-width: 100%;    /* Ограничение максимальной ширины для удобного чтения */
    padding: 5px;    
    font-family: "Baskervville SC", serif;
    font-weight: 500;
    font-size: 22px;
    border: 1px solid #5A2F95;
    box-shadow: 10px 5px 5px #524563;
    margin: 0 20px 20px 20px;
}
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Отступы между карточками */
  }
  
  .card-container > div {
    flex: 0 1 calc(50% - 20px); /* Две карточки в ряду */
    box-sizing: border-box;
  }
.videotext{
    font-size: 25px;
    font-family: "Exo 2", sans-serif;
    font-optical-sizing: auto;
    color: #5A2F95;
    font-weight: 600;
    border-bottom: 1px solid #5A2F95;
    margin-bottom: 10px;
    width: 100%;
} 
.videotext2{
    font-size: 25px;
    font-family: "Exo 2", sans-serif;
    font-optical-sizing: auto;
    color: #ffffff;
    font-weight: 600;
    border-bottom: 1px solid #5A2F95;
    margin-bottom: 10px;
    width: 100%;
} 
.vidcontainer{
    display: flex;
    flex-direction: column;
}
.cardimg:hover {
    transform: scale(1.1); /* Увеличиваем изображение на 10% при наведении */
    opacity: 95%;
}
.btngroup{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #5A2F95;
    border-right: 1px solid #5A2F95;
    padding: 20px;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 13%, rgba(98,19,162,1) 53%, rgba(27,6,33,1) 89%);
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  

  .btnbottom {
    text-decoration: none;
    border: 1px solid rgb(146, 148, 248);
    position: relative;
    overflow: hidden;
    font-size: 1.5rem;
    width: 100%;
    padding: 20px;
    color: #ffffff;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
  }
  
  .btnbottom:hover {
    box-shadow: 1px 1px 25px 10px rgba(146, 148, 248, 0.4);
  }
  
  .btnbottom:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(146, 148, 248, 0.4),
      transparent
    );
    transition: all 650ms;
  }
  
  .btnbottom:hover:before {
    left: 100%;
  }

/* @media (max-width: 767px){
  .btngroupcontainer{
    flex-direction: column;
  }
}
   */
   @media (max-width: 1200px) {
    .btngroupcontainer{
      display: flex;
      flex-direction: column;
    }
    .btngroup{
      width: 100%;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

  }
   @media (max-width: 768px) {
    .btngroupcontainer{
      display: flex;
      flex-direction: column;
    }
    .btngroup{
      width: 100%;
    }
    .introtext{
      text-align:center ;  /* Выравнивание текста по центру */
      margin: 0;      /* Выравнивание самого блока по центру */
      max-width: 100%;    /* Ограничение максимальной ширины для удобного чтения */
      padding: 5px;    
      font-family: "Baskervville SC", serif;
      font-weight: 500;
      font-size: 20px;
      border: 1px solid #5A2F95;
      box-shadow: 10px 5px 5px #524563;
      margin: 0 20px 20px 20px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .btnbottom {
    text-decoration: none;
    border: 1px solid rgb(146, 148, 248);
    position: relative;
    overflow: hidden;
    font-size: 1.2rem;
    width: 100%;
    color: #ffffff;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
  }
  }
  
  /* Медиазапрос для устройств с шириной экрана 480px и меньше */
  @media (max-width: 480px) {
    .btngroupcontainer{
      display: flex;
      flex-direction: column;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .introtext{
      text-align:center ;  /* Выравнивание текста по центру */
      margin: 0;      /* Выравнивание самого блока по центру */
      max-width: 100%;    /* Ограничение максимальной ширины для удобного чтения */
      padding: 5px;    
      font-family: "Baskervville SC", serif;
      font-weight: 500;
      font-size: 18px;
      border: 1px solid #5A2F95;
      box-shadow: 10px 5px 5px #524563;
      margin: 0 20px 20px 20px;
  }
  .btnbottom {
    text-decoration: none;
    border: 1px solid rgb(146, 148, 248);
    position: relative;
    overflow: hidden;
    font-size: 1rem;
    width: 100%;
    color: #ffffff;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
  }

  }                 
