.theatrewrapper{
    padding-left: 0;
    padding-right: 0;
}
.theatrecarousel{
display: flex;
align-items: center;
justify-content: center;
margin-top: 20px;
flex-direction: column;
}
.theatretitle{
    letter-spacing: 2px;
    font-size: 36px;
}
.theatretext{
max-width: 100%;
max-height: auto;
margin-top: 20px;
text-align: justify;
font-size: 20px;
}
@media screen and (width< 768px) {
    .theatretitle{
        letter-spacing: 1px;
        font-size: 32px;
    }
    .theatretext{
        font-size: 16px;
    }
  }