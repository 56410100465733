.cardwrapper{
    display: flex;
    flex-direction: column;
    border-top:1px solid #5A2F95 ;
    margin-top: 10px;
}
.image-container {
    width: 100%;
    height: 600px; /* Фиксированная высота для изображений */
    overflow: hidden; /* Обрезка изображения */
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Масштабирование изображения */
  }
  .cardwrapper a{
font-size: 18px;
  }
  
  /* Адаптивные стили для разных устройств */
  @media (max-width: 768px) {
    .image-container {
      height: 150px; /* Меньшая высота для мобильных устройств */
    }
  }
  
  @media (max-width: 576px) {
    .image-container {
      height: 120px; /* Еще меньшая высота для мобильных устройств */
    }
  }
.eventtitle{
  width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.eventtitle h2{
  font-size: 20px;
}
.eventtitle p{
  padding-top: 15px;
  text-wrap: nowrap;
}