/* Основные стили для навбара */
.navbarmain {
    background-color: rgb(33, 15, 46);
    color: white;
  }
  
  .navbarmain .navbar-brand {
    display: flex;
    align-items: center;
  }
  
  .navbarmain .logotext {
    margin-left: 15px;
    font-size: 24px;
    margin-bottom: 0;
  }
  
  .navbarmain .navbar-toggler {
    border-color: white;
  }
  
  
  /* Стили для кнопок навигации */
  .navbarbtn {
    font-size: 18px;
    color: white;
    margin: 0 10px; /* Отступы между ссылками */
  }
  
  .navbarbtn:hover {
    color: white;
    text-decoration: underline;
  }
  
  /* Адаптивные стили */
  @media (max-width: 992px) {
    .navbarmain .navbar-collapse {
      margin-top: 10px;
    }
  
    .navbarmain .navbar-nav {
      text-align: center;
    }
  
    .navbarmain .navbar-nav .nav-link {
      padding: 10px 0; /* Отступы для ссылок в бургер-меню */
    }
  }
  