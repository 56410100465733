.histwrapper{
    padding: 0;
}
.histwrapper2{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.histtitle{
    text-align: center;
    margin-top: 15px;
    font-size: 42px;
    letter-spacing: 2px;
    color: #352d3f;
}