.achivtitle{
    text-align: center;
    margin-top: 15px;
    font-size: 42px;
    letter-spacing: 2px;
    color: #352d3f;
}
.achivwrapper2{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.achivwrapper{
    padding: 0;
}
.achivtext1{
    font-size: 22px;
    text-align: justify;
    margin-top: 15px;
}
.achivtext2{
    font-size: 22px;
    text-align: justify;
    margin-top: 10px;
}
.achivtext3{
    font-size: 22px;
    text-align: justify;
    margin-top: 10px;
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8); /* Полупрозрачный фон */
  }
  
  /* Стили для увеличенного изображения */
  .modal-content {
    max-width: 90%; /* Максимальная ширина для горизонтальных фотографий */
    max-height: 90%; /* Максимальная высота для вертикальных фотографий */
    margin: auto;
    display: block;
    border-radius: 10px;
    object-fit: contain; /* Сохраняем пропорции изображений */
    background-color: transparent; /* Убираем белый фон */
  }
  
  /* Кнопка закрытия */
  .close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }