.eventpagewrapper{

}
.eventpagewrapper2{
    display: flex;
    flex-direction: column;
}
.eventpagetitle{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #5A2F95;
    margin-top: 10px;
}
.eventpagetitle p{
    padding-top: 20px;
}
.eventpageimg{
    margin-top: 10px;
width: 100%;
height: 50%;
}
.eventpagetext{
    text-align: justify;
    margin-top: 10px;
    font-size: 20px;
}
.eventpagephotos {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    width: 100%;
    gap: 16px;
    margin-top: 20px;
  }
  
  .eventpagephoto {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8); /* Полупрозрачный фон */
  }
  
  /* Стили для увеличенного изображения */
  .modal-content {
    max-width: 90%; /* Максимальная ширина для горизонтальных фотографий */
    max-height: 90%; /* Максимальная высота для вертикальных фотографий */
    margin: auto;
    display: block;
    border-radius: 10px;
    object-fit: contain; /* Сохраняем пропорции изображений */
    background-color: transparent; /* Убираем белый фон */
  }
  
  /* Кнопка закрытия */
  .close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  .show-more-container{
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
.show-more-button{
  border: 1px solid rgb(43, 110, 182);;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 10px;
  color: white;
  background-color: rgb(43, 110, 182);;
}

  @media (max-width: 768px) {
    .eventpagetext{
      text-align: justify;
      margin-top: 10px;
      font-size: 14px;
  }
  }