.contactmenu {
  width: 100%;
  padding: 20px; /* Добавьте отступы для удобства */
  background-color: rgba(23, 7, 34, 0.952);
  color:#ffffff;

}

.clubsname {
  font-size: 36px;
  margin-bottom: 20px; /* Отступ под заголовком */
  border-bottom: 1px solid white;
}

.contactmenutext p, 
.contactmenu p {
  font-size: 20px;
  margin: 5px 0; /* Отступы между параграфами */
}


 @media (max-width: 768px) {
  .contactmenu {
    width: 100%;
    padding: 17px; /* Добавьте отступы для удобства */
    background-color: rgba(23, 7, 34, 0.952);
    color:#ffffff;
  
  }
  
  .clubsname {
    font-size: 36px;
    margin-bottom: 17px; /* Отступ под заголовком */
    border-bottom: 1px solid white;
  }
  
  .contactmenutext p, 
  .contactmenu p {
    font-size: 18px;
    margin: 5px 0; /* Отступы между параграфами */
  }
}

/* Медиазапрос для устройств с шириной экрана 480px и меньше */
@media (max-width: 480px) {
  .contactmenu {
    width: 100%;
    padding: 15px; /* Добавьте отступы для удобства */
    background-color: rgba(23, 7, 34, 0.952);
    color:#ffffff;
  
  }
  
  .clubsname {
    font-size: 36px;
    margin-bottom: 15px; /* Отступ под заголовком */
    border-bottom: 1px solid white;
  }
  
  .contactmenutext p, 
  .contactmenu p {
    font-size: 15px;
    margin: 5px 0; /* Отступы между параграфами */
  }

}                 